var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{staticClass:"edit-customer-dialog",attrs:{"visible":_vm.localVisible,"title":"Редактировать покупателя"},on:{"update:visible":function($event){_vm.localVisible=$event}}},[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('v-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}},scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('v-form-row',[_c('v-form-field',{attrs:{"label":"ТЕЛЕФОН КЛИЕНТА","rules":_vm.rules.phone,"error-message":_vm.errors.phone},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isError = ref.isError;
return [_c('v-input-phone',{attrs:{"is-error":isError},scopedSlots:_vm._u([(_vm.successAppend)?{key:"append",fn:function(){return [(_vm.successAppend === 'success')?_c('v-success',{staticClass:"edit-customer-dialog__success-icon"}):_c('v-not-success')]},proxy:true}:null],null,true),model:{value:(_vm.customer.phone),callback:function ($$v) {_vm.$set(_vm.customer, "phone", $$v)},expression:"customer.phone"}})]}},{key:"error",fn:function(ref){
var errorMessage = ref.errorMessage;
return [_c('v-form-user-error',{attrs:{"error-message":errorMessage,"user":_vm.phoneSearchUser}})]}}],null,true)})],1),_c('v-form-row',[_c('v-form-field',{attrs:{"label":"ИМЯ КЛИЕНТА","rules":_vm.rules.name,"error-message":_vm.errors.name},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isError = ref.isError;
return [_c('v-input',{attrs:{"is-error":isError,"type":"text"},model:{value:(_vm.customer.name),callback:function ($$v) {_vm.$set(_vm.customer, "name", $$v)},expression:"customer.name"}})]}}],null,true)})],1),_c('v-form-row',[_c('v-form-field',{attrs:{"label":"EMAIL","rules":_vm.rules.email,"error-message":_vm.errors.email},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isError = ref.isError;
return [_c('v-input',{attrs:{"is-error":isError,"type":"email"},model:{value:(_vm.customer.email),callback:function ($$v) {_vm.$set(_vm.customer, "email", $$v)},expression:"customer.email"}})]}}],null,true)})],1),(_vm.isRoleAdmin)?_c('v-form-row',[_c('v-form-field',{attrs:{"label":"АГЕНТ","rules":_vm.rules.agent,"error-message":_vm.errors.agent},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isError = ref.isError;
return [_c('v-select',{attrs:{"label":"name","append-to-body":"","is-error":isError,"options":_vm.agentOptions,"loading":_vm.agentOptionsLoading},model:{value:(_vm.customer.agent),callback:function ($$v) {_vm.$set(_vm.customer, "agent", $$v)},expression:"customer.agent"}})]}}],null,true)})],1):_vm._e(),_c('v-form-row',[_c('v-form-field',{attrs:{"label":"СТАТУС","rules":_vm.rules.status,"error-message":_vm.errors.status},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isError = ref.isError;
return [_c('v-select',{attrs:{"label":"name","append-to-body":"","is-error":isError,"options":_vm.statusOptions},model:{value:(_vm.customer.status),callback:function ($$v) {_vm.$set(_vm.customer, "status", $$v)},expression:"customer.status"}})]}}],null,true)})],1),_c('v-form-row',[_c('v-form-field',{attrs:{"label":"ИСТОЧНИК ЛИДА","rules":_vm.rules.callSource,"error-message":_vm.errors.callSource},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isError = ref.isError;
return [_c('v-select',{attrs:{"label":"name","append-to-body":"","is-error":isError,"options":_vm.callSourceOptions},model:{value:(_vm.customer.callSource),callback:function ($$v) {_vm.$set(_vm.customer, "callSource", $$v)},expression:"customer.callSource"}})]}}],null,true)})],1)]},proxy:true},{key:"footer",fn:function(){return [_c('v-button',{attrs:{"disabled":_vm.isFieldsNotChanged,"primary":"","type":"submit"}},[_vm._v("Сохранить")])]},proxy:true}],null,true)})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }