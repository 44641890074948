<template>
  <match-media v-slot="{ mobile }">
    <div class="customer-edit-pending-dialog-content">
      <h2 v-if="!mobile" class="customer-edit-pending-dialog-content__title">{{ title }}</h2>
      <!--      todo: повторяется шаблон в editDialog, придумать как вынести в отдельный компонент-->
      <v-form-row>
        <v-form-field label="ТЕЛЕФОН" :error-message="disableFields ? '' : backendErrors.phone">
          <template #default="{ isError }">
            <v-input-phone
              :value="localCustomerData.phone"
              :disabled="disableFields"
              :is-error="isError"
              @input="updateLocalCustomerData('phone', $event)"
            >
              <template v-if="successAppend" #append>
                <v-success
                  v-if="successAppend === 'success'"
                  class="customer-edit-pending-dialog-content__success-icon"
                ></v-success>
                <v-not-success v-else></v-not-success> </template
            ></v-input-phone>
          </template>
          <template #error="{ errorMessage }">
            <v-form-user-error :error-message="errorMessage" :user="phoneSearchUser" />
          </template>
        </v-form-field>
      </v-form-row>

      <v-form-row>
        <v-form-field label="ИМЯ ПОКУПАТЕЛЯ" :error-message="disableFields ? '' : backendErrors.name">
          <template #default="{ isError }">
            <v-input
              :value="localCustomerData.name"
              :disabled="disableFields"
              :is-error="isError"
              type="text"
              @input="updateLocalCustomerData('name', $event)"
            />
          </template>
        </v-form-field>
      </v-form-row>

      <v-form-row>
        <v-form-field label="EMAIL" :error-message="disableFields ? '' : backendErrors.email">
          <template #default="{ isError }">
            <v-input
              :value="localCustomerData.email"
              :disabled="disableFields"
              :is-error="isError"
              type="email"
              @input="updateLocalCustomerData('email', $event)"
            />
          </template>
        </v-form-field>
      </v-form-row>

      <v-form-row>
        <v-form-field label="СТАТУС" :error-message="disableFields ? '' : backendErrors.status">
          <template #default="{ isError }">
            <v-select
              :value="localCustomerData.status"
              label="name"
              :disabled="disableFields"
              append-to-body
              :is-error="isError"
              :options="statusOptions"
              @input="updateLocalCustomerData('status', $event)"
            />
          </template>
        </v-form-field>
      </v-form-row>

      <v-form-row v-if="isRoleAdmin">
        <v-form-field label="АГЕНТ" :error-message="disableFields ? '' : backendErrors.agent">
          <template #default="{ isError }">
            <v-select
              :value="localCustomerData.agent"
              label="name"
              :disabled="disableFields"
              append-to-body
              :is-error="isError"
              :options="agentOptions"
              @input="updateLocalCustomerData('agent', $event)"
            />
          </template>
        </v-form-field>
      </v-form-row>

      <v-form-row>
        <v-form-field label="ИСТОЧНИК ЛИДА" :error-message="disableFields ? '' : backendErrors.callSource">
          <template #default="{ isError }">
            <v-select
              :value="localCustomerData.callSource"
              label="name"
              :disabled="disableFields"
              append-to-body
              :is-error="isError"
              :options="callSourceOptions"
              @input="updateLocalCustomerData('callSource', $event)"
            />
          </template>
        </v-form-field>
      </v-form-row>
    </div>
  </match-media>
</template>

<script>
import VFormRow from '@/components/form/VFormRow.vue'
import VFormField from '@/components/form/VFormField.vue'
import VInput from '@/components/common/VInput.vue'
import VInputPhone from '@/components/common/VInputPhone.vue'
import VSelect from '@/components/common/VSelect.vue'
import { mapGetters } from 'vuex'
import { MODULE_SESSION } from '@/store/modules/session/session.types'
import { MatchMedia } from 'vue-component-media-queries'
import { authService } from '@/services/http'
import VFormUserError from '@/components/form/VFormUserError.vue'
import VSuccess from '@/components/icons/VSuccess.vue'
import VNotSuccess from '@/components/icons/VNotSuccess.vue'

export default {
  name: 'CustomerEditPendingDialogFormContentWrapper',
  components: { VNotSuccess, VSuccess, VFormUserError, MatchMedia, VSelect, VInputPhone, VInput, VFormField, VFormRow },
  props: {
    title: { type: String, default: '' },
    customerData: { type: Object, default: () => {} },
    disableFields: { type: Boolean, default: false },
    editMode: { type: Boolean, default: false },
    agentOptions: { type: Array, default: () => [] },
    callSourceOptions: { type: Array, default: () => [] },
    statusOptions: { type: Array, default: () => [] },
    backendErrors: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data: () => {
    return {
      phoneSearchUser: null
    }
  },
  computed: {
    successAppend() {
      if (this.phoneSearchUser) {
        if (typeof this.phoneSearchUser === 'string') {
          return 'success'
        }
        return 'not success'
      }
      return ''
    },
    customerPhone() {
      return this.localCustomerData.phone
    },
    ...mapGetters({
      isRoleAdmin: `${MODULE_SESSION}/isRoleAdmin`
    }),
    localCustomerData: {
      get() {
        return this.customerData
      },
      set(newValue) {
        this.$emit('change', { ...this.localCustomerData, ...newValue })
      }
    }
  },
  watch: {
    customerPhone: {
      handler(val) {
        if (val.length === 11) {
          authService.select({ phone: val }).then(({ results }) => {
            this.phoneSearchUser = results[0] || 'noMatches'
          })
        } else {
          this.phoneSearchUser = null
        }
      }
    }
  },
  methods: {
    updateLocalCustomerData(key, value) {
      this.localCustomerData = { [key]: value }
    }
  }
}
</script>

<style scoped lang="scss">
.customer-edit-pending-dialog-content {
  &__title {
    margin-bottom: 20px;
  }
  &__success-icon {
    width: 16px;
  }
}
</style>
