<template>
  <v-section title="Просмотренные объекты" class="customer-edit-viewed-objects">
    <ul>
      <objects-realty-list-item
        v-for="objectRealty in formattedObjects"
        :key="objectRealty.id"
        v-bind="objectRealty"
        disable-links
        compact-view
        hide-actions
        class="customer-edit-viewed-objects__list-item"
      >
      </objects-realty-list-item>
    </ul>
  </v-section>
</template>

<script>
import VSection from '@/components/common/VSection.vue'
import ObjectsRealtyListItem from '@/components/ObjectsRealty/ListItem.vue'
import { formatObjectRealty } from '@/utils/formatters'

export default {
  name: 'CustomerEditViewedObjects',
  components: { ObjectsRealtyListItem, VSection },
  props: {
    adverts: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  computed: {
    formattedObjects() {
      return this.adverts.map(formatObjectRealty)
    }
  }
}
</script>

<style lang="scss">
.customer-edit-viewed-objects {
  &__list-item {
    background: $--white;
    border: 1px solid $--white-300;
    border-radius: $--main-border-radius;
    padding: 0;

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
}
</style>
