<template>
  <v-dialog :visible.sync="localVisible" title="Редактировать покупателя" class="edit-customer-dialog">
    <validation-observer v-slot="{ handleSubmit }">
      <v-form @submit.prevent="handleSubmit(onSubmit)">
        <!--      todo: повторяется шаблон в OwnerEditPendingDialogFormContentWrapper, вынести в отдельный компонент-->
        <template #default>
          <v-form-row>
            <v-form-field label="ТЕЛЕФОН КЛИЕНТА" :rules="rules.phone" :error-message="errors.phone">
              <template #default="{ isError }">
                <v-input-phone v-model="customer.phone" :is-error="isError">
                  <template v-if="successAppend" #append>
                    <v-success
                      v-if="successAppend === 'success'"
                      class="edit-customer-dialog__success-icon"
                    ></v-success>
                    <v-not-success v-else></v-not-success>
                  </template>
                </v-input-phone>
              </template>
              <template #error="{ errorMessage }">
                <v-form-user-error :error-message="errorMessage" :user="phoneSearchUser" />
              </template>
            </v-form-field>
          </v-form-row>

          <v-form-row>
            <v-form-field label="ИМЯ КЛИЕНТА" :rules="rules.name" :error-message="errors.name">
              <template #default="{ isError }">
                <v-input v-model="customer.name" :is-error="isError" type="text" />
              </template>
            </v-form-field>
          </v-form-row>

          <v-form-row>
            <v-form-field label="EMAIL" :rules="rules.email" :error-message="errors.email">
              <template #default="{ isError }">
                <v-input v-model="customer.email" :is-error="isError" type="email" />
              </template>
            </v-form-field>
          </v-form-row>

          <v-form-row v-if="isRoleAdmin">
            <v-form-field label="АГЕНТ" :rules="rules.agent" :error-message="errors.agent">
              <template #default="{ isError }">
                <v-select
                  v-model="customer.agent"
                  label="name"
                  append-to-body
                  :is-error="isError"
                  :options="agentOptions"
                  :loading="agentOptionsLoading"
                />
              </template>
            </v-form-field>
          </v-form-row>
          <v-form-row>
            <v-form-field label="СТАТУС" :rules="rules.status" :error-message="errors.status">
              <template #default="{ isError }">
                <v-select
                  v-model="customer.status"
                  label="name"
                  append-to-body
                  :is-error="isError"
                  :options="statusOptions"
                />
              </template>
            </v-form-field>
          </v-form-row>
          <v-form-row>
            <v-form-field label="ИСТОЧНИК ЛИДА" :rules="rules.callSource" :error-message="errors.callSource">
              <template #default="{ isError }">
                <v-select
                  v-model="customer.callSource"
                  label="name"
                  append-to-body
                  :is-error="isError"
                  :options="callSourceOptions"
                />
              </template>
            </v-form-field>
          </v-form-row>
        </template>

        <template #footer>
          <v-button :disabled="isFieldsNotChanged" primary type="submit">Сохранить</v-button>
        </template>
      </v-form>
    </validation-observer>
  </v-dialog>
</template>

<script>
import VDialog from '@/components/common/VDialog.vue'
import VForm from '@/components/form/VForm.vue'
import VFormRow from '@/components/form/VFormRow.vue'
import VFormField from '@/components/form/VFormField.vue'
import VInput from '@/components/common/VInput.vue'
import VInputPhone from '@/components/common/VInputPhone.vue'
import VButton from '@/components/common/VButton.vue'
import VSelect from '@/components/common/VSelect.vue'
import { agentsService, authService, customersService } from '@/services/http'
import { formatAgentObject } from '@/utils/formatters'
import { getFirstErrorForFields, cloneDeep, isFieldsNotChanged } from '@/utils/common'
import { loadingService } from '@/services/loading'
import { mapGetters } from 'vuex'
import { MODULE_SESSION } from '@/store/modules/session/session.types'
import VFormUserError from '@/components/form/VFormUserError.vue'
import VSuccess from '@/components/icons/VSuccess.vue'
import VNotSuccess from '@/components/icons/VNotSuccess.vue'

export default {
  name: 'CustomerEditDialog',
  components: {
    VNotSuccess,
    VSuccess,
    VFormUserError,
    VDialog,
    VFormField,
    VFormRow,
    VButton,
    VInput,
    VForm,
    VSelect,
    VInputPhone
  },
  props: {
    visible: { type: Boolean, required: true },
    customerData: { type: Object, default: () => null }
  },
  data: () => {
    return {
      customer: {
        name: '',
        phone: '',
        email: '',
        agent: null,
        status: null,
        callSource: null
      },
      loading: false,
      errors: {},
      agentOptionsLoading: false,
      agentOptions: [],
      statusOptions: [],
      callSourceOptions: [],
      phoneSearchUser: null
    }
  },
  computed: {
    successAppend() {
      if (this.phoneSearchUser) {
        if (typeof this.phoneSearchUser === 'string') {
          return 'success'
        }
        return 'not success'
      }
      return ''
    },
    customerPhone() {
      return this.customer.phone
    },
    isFieldsNotChanged() {
      return isFieldsNotChanged(this.customerData, this.customer, { isAdmin: this.isRoleAdmin })
    },
    // todo: Подумать как объединить changedValues с src/components/Owner/EditDialog.vue
    changedValues() {
      const changedKeys = []
      const keys = Object.keys(this.customer)
      keys.forEach(key => {
        if (typeof this.customer[key] === 'object') {
          if (this.customer[key]?.id !== this.customerData[key]?.id) {
            changedKeys.push(key)
          }
        } else if (this.customer[key] !== this.customerData[key]) {
          changedKeys.push(key)
        }
      })
      return { fields: changedKeys }
    },
    ...mapGetters({
      isRoleAdmin: `${MODULE_SESSION}/isRoleAdmin`
    }),
    rules() {
      return {
        object: `${this.isCreation ? 'required|arrayLength:1' : ''}`,
        email: `${this.isCreation ? 'required|email' : ''}`,
        agent: `${this.isCreation ? 'required' : ''}`,
        phone: `${this.isCreation ? 'required' : ''}`,
        status: `${this.isCreation ? 'required' : ''}`,
        callSource: `${this.isCreation ? 'required' : ''}`,
        name: `${this.isCreation ? 'required' : ''}`
      }
    },

    isCreation() {
      return !(this.customerData && this.customerData.id)
    },
    localVisible: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  watch: {
    customerPhone: {
      handler(val) {
        if (val.length === 11 && val !== this.customerData.phone) {
          authService.select({ phone: val }).then(({ results }) => {
            this.phoneSearchUser = results[0] || 'noMatches'
          })
        } else {
          this.phoneSearchUser = null
        }
      }
    },
    loading(val) {
      loadingService.setGlobalLoading(val)
    },
    customerData: {
      immediate: true,
      handler() {
        this.resetLocalState()
      }
    },
    visible: {
      immediate: true,
      handler(val) {
        if (val) this.resetLocalState()
      }
    }
  },
  created() {
    if (this.isRoleAdmin) {
      this.selectAgents()
    }
    this.selectStatuses()
    this.selectCallSources()
  },
  methods: {
    updateCustomer({ id, ...customerData }) {
      this.loading = true
      const isEditProfile = true
      return customersService.update(id, customerData, isEditProfile, this.changedValues).finally(() => {
        this.loading = false
      })
    },
    selectAgents() {
      this.agentOptionsLoading = true
      return agentsService
        .select()
        .then(agentList => {
          this.agentOptions = agentList.map(agent => formatAgentObject(agent))
        })
        .finally(() => {
          this.agentOptionsLoading = false
        })
    },
    selectStatuses() {
      return customersService.getCustomerStatusesMap().then(statusesList => {
        this.statusOptions = statusesList
      })
    },
    selectCallSources() {
      return customersService.getCallSourcesMap().then(callSourcesList => {
        this.callSourceOptions = callSourcesList
      })
    },
    resetLocalState() {
      const emptyCustomer = {
        name: '',
        phone: '',
        email: '',
        agent: null,
        callSource: null,
        status: null
      }
      this.customer = { ...emptyCustomer, ...cloneDeep(this.customerData) }
      this.errors = {}
    },

    onSubmit() {
      this.updateCustomer(this.customer)
        .then(newData => {
          this.$emit('submitted', newData)
          this.localVisible = false
        })
        .catch(error => {
          const flatErrors = { ...error.details, ...error.details.customerProfile }
          this.errors = getFirstErrorForFields(flatErrors)
        })
    }
  }
}
</script>

<style lang="scss">
.edit-customer-dialog {
  &__success-icon {
    width: 16px;
  }
}
</style>
